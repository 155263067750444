/**
 * Controller do componente Frequencia List (frequencia-list)
 */
(function(){
	angular.module('frequenciaListModule').controller('FrequenciaListComponentController', 
		['$scope', '$log', 'Notification', '$ionicModal', '$http', 'SessionData', '$ionicLoading', 
			'Aluno.Frequencia', 'FrequenciaService','DisciplinasMatriculadasService', FrequenciaListComponentController]);
	
	function FrequenciaListComponentController($scope, $log, Notification, $ionicModal, $http, SessionData, $ionicLoading,
		 Aluno_Frequencia, FrequenciaService, DisciplinasMatriculadasService){
		var ctrl = this;
		
		$log.debug('[FrequenciaListComponentController]:ctrl=', ctrl);
		
		ctrl.sessionData = SessionData;
		
		var alunos = ctrl.sessionData.aluno;
	    ctrl.aluno = alunos.list[alunos.selecionado];
	    
	    ctrl.getWindowWidth = getWindowWidth;
        
		ctrl.faltas = [];
		ctrl.faltaTotal = 0;
		ctrl.frequenciaTotal = 100;
		ctrl.aulasPrevistas = 0;
		
		$ionicLoading.show({
    		content : 'Loading',
    		animation : 'fade-in',
    		showBackdrop : true,
    		maxWidth : 200,
    		showDelay : 1
    	});
		
		// Chama o serviço REST
		Aluno_Frequencia.query({codAluno: encodeURIComponent(ctrl.aluno.codAluno), codPessoa: ctrl.aluno.id}, handleSuccess, handleFailure);
		
	    // Tratamento de Sucesso da chamada ao serviço REST
	    function handleSuccess(result){
	    	ctrl.faltas = result.content;
	    	for(var i=0; i<ctrl.faltas.length; i++) {
	    		if(ctrl.faltas[i].obrigatoria === 'S'){
	    			ctrl.faltaTotal += ctrl.faltas[i].numeroFaltas;
	    			ctrl.aulasPrevistas += ctrl.faltas[i].aulasPrevistas;
	    		}
	    	}
	    	
			if((ctrl.aulasPrevistas) > 0) {
				ctrl.frequenciaTotal = (((ctrl.aulasPrevistas - ctrl.faltaTotal) / ctrl.aulasPrevistas)*100).toPrecision(4);
			}
	    	$log.debug('FrequenciaListComponentController.Aluno_Frequencia.handleSuccess():\tfaltas=', ctrl.faltas);
	    	$ionicLoading.hide();
	    }
	    
	    
	    // Tratamento de Erro da Chamada ao serviço REST
	    function handleFailure(reason){
	    	$ionicLoading.hide();
	    	$log.debug('[FrequenciaListComponentController.Aluno_Frequencia.handleFailure]\treason=', reason);
	    	
	        var keys = Object.keys(reason);
	        var str = '';
	        for (var k in keys) {
	          str += reason[k] + '\n';
	        }
	        
	        $log.debug('[FrequenciaListComponentController.Aluno_Frequencia.handleFailure]\treason=' + str);
	        Notification.error('[FrequenciaListComponentController.Aluno_Frequencia.handleFailure]\treason=' + str);
	    }
	    
	    function getWindowWidth(){
	    	return window.innerWidth;
	    }

		$ionicModal.fromTemplateUrl('./js/custom-components/frequencia-list/modal.detalhes.frequencia.html', {
	        scope: $scope,
	        animation: 'slide-in-up'
	    }).then(function(modal) {
	        $scope.modal = modal;
	    });

		$scope.getWidth = function(){
            return $(window).width();
        };

		$scope.detalharFaltas = function(codDisciplina){
			$ionicLoading.show();

			FrequenciaService.listarDetalhesFrequencia(ctrl.aluno.codAluno, ctrl.aluno.ano, codDisciplina, ctrl.aluno.turma, ctrl.aluno.semestre)
				.then(function handleSuccess(result) {
					$ionicLoading.hide();
					$scope.detalhesFaltas = result;
					$scope.modal.show()
	        }).catch(function(err) {
              $ionicLoading.hide();
          });
		}
	}
})();
