(function($app) {
angular.module('custom.services', ['ngStorage'])

/**
 * ------------------
 *  Storage / Session
 * ------------------
 *
 * Abstração para acesso à um serviço de 'storage'.
 * Deve ser utilizado para expandir o conceito, sem
 * no entanto, impactar o restante do código.
 */

.factory( 'Storage', [ '$rootScope', '$sessionStorage', '$localStorage',
  function($rootScope, $sessionStorage, $localStorage){

    let service = {
      storage: $sessionStorage // default value
    };

    /**
     * Remove item do storage
     *
     * @returns valor do item removido
     */
    service.delete = function (name) {
      let stored = this.get(name);
      delete this.storage[name];
      return stored;
    };

    /**
     * Limpa o storage
     */
    service.clear = function (name) {
      return this.storage.$reset();
    };

    /**
     * @returns item do storage
     */
    service.get = function (name) {
      return this.storage[name];
    };

    /**
     * Atribui 'value' a chave do storage 'name'
     * @returns valor atribuido
     */
    service.set = function (name, value) {
      this.storage[name] = value;
      return this.get(name);
    };

    /**
     * Altera o serviço de storage sendo utilizado
     */
    service.use = function (storage) {
      if (storage === 'sessionStorage') {
        this.storage = $sessionStorage;
      }
      else if (storage === 'localStorage') {
        this.storage = $localStorage;
      }
    };

    return service;
  }
])

/**
 * ----------------------------
 *  HttpPendingRequestService
 * ----------------------------
 *
 * Guarda array de referências à promise dos requests do $state
 * corrente e permite o cancelamento de requests pendentes, por
 * exemplo, quando um erro ocorre e não se deseja continuar com
 * as demais operações.
 */

.factory('HttpPendingRequestService', function ($q) {
  let service = {};
  var cancelPromises = [];

  service.createTimeout = function() {
    var cancelPromise = $q.defer();
    cancelPromises.push(cancelPromise);
    return cancelPromise.promise;
  }

  service.cancelAll = function() {
    angular.forEach(cancelPromises, function (cancelPromise) {
      cancelPromise.resolve();
    });
    cancelPromises.length = 0;
  }

  return service;
})

/**
 * ------------------
 *  LogoutService
 * ------------------
 *
 * Limpa informações de usuário e realiza redirecionamento adequado.
 */

.factory( 'LogoutService', [ '$log', '$state', '$location', '$http', 'SessionData', 'serviceAlunos', 'Storage',
  function($log, $state, $location, $http, SessionData, serviceAlunos, Storage){

    let service = {};

    let codSessao = Storage.get("sessaoLogConexoes");
    let codPessoa;
    let codAluno;
 
    service.logout = () =>{
      if ( SessionData && SessionData.user && SessionData.user.oauthEnabled && SessionData.user.logoutUrl){
        /*
         * Quando o mecanismo de integração OAuth2 estiver ativo, temos uma URL de sign out com o IdP
         * que encerra a sessão com o terceiro, redireciona para o app para invalidar a sessão interna
         * e realizar o redirect de acordo com lógica de negócio da API Aluno.
         */
        window.location.assign(SessionData.user.logoutUrl);
        
        SessionData.clear();
        Storage.clear();
      }
      else {
        // atualiza os campos para o caso de login após realização do logout
        if (!SessionData.isEmpty('aluno')) {
          codPessoa = SessionData.aluno.list[0].user.id;
          codAluno = SessionData.aluno.list[0].codAluno;
        } else{
          codPessoa = undefined;
          codAluno = undefined;
        }
  
        codSessao = Storage.get("sessaoLogConexoes")

        // previne a chamada do logout quando não existir uma sessão
        if(SessionData && SessionData.user){
        if((SessionData.user.id || SessionData.user.login) != undefined) {
          param = {
            "aluno": encodeURIComponent(codAluno),
            "sessao": codSessao
          }

          serviceAlunos.geraLogConexao(param).then(function(){
            return $http({ method : 'POST'   , url    : 'logout' });
          })
          .then(()=>{

            if (codPessoa && codAluno) {
              serviceAlunos.redirectUrlRetorno(codPessoa, codAluno).then(
                function onSuccess(response){
                  SessionData.clear();
                  Storage.clear();
                  if (response.conteudo !== null && response.conteudo !== undefined){
                    $(location).attr('href', response.conteudo);
                  } else {
                    $state.go('login', {}, {reload: true});
                  }

              }, function onFailure(response){
                SessionData.clear();
                Storage.clear();
                $state.go('login', {}, {reload: true});
              });
            }
            else { // Erro no login
              SessionData.clear();
              Storage.clear();
            }
          })
          .catch(function(err){
            if(err.status === 401){
              SessionData.clear();
              Storage.clear();
              $state.go('login', {}, {reload: true});
            } else{
              $log.error('Logout error:', err);
            }

          })
        }
      }
      }
    }
 
    return service;
  }
])

}(app));
