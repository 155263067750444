angular.module('MyApp')
  .service('serviceAlunos', [
	  'SessionData',
	  '$state',
	  'Notification',
	  'Aluno.Foto',
	  '$rootScope',
	  '$q',
	  'Configs',
	  'Aluno.AvaliacaoInstitucional',
	  '$resource',
	  '$ionicLoading',
	  '$ionicLoading',
	  '$translate',
	  'Storage',
	  'MatriculaService',
	  'UserService',
	  '$ionicModal',
	  '$log',
	  function(SessionData, $state, Notification, Aluno_Foto, $rootScope, $q, Configs, Aluno_AvaliacaoInstitucional, $resource, $ionicLoading, $ionicLoading, $translate, Storage, MatriculaService, UserService, $ionicModal, $log) {

		function loading(value) {
			if (value)
				$ionicLoading.show({
					content: 'Loading',
					animation: 'fade-in',
					showBackdrop: true,
					maxWidth: 200,
					showDelay: 1
				});
			else $ionicLoading.hide();
		}

		this.selecionarAluno = function(aluno) {
			selecionarAlunoAsync(aluno).then(() => {
				if (!SessionData.aluno.list[SessionData.aluno.selecionado].transacoes) {
					UserService.buscarTransacoesAutorizadasAluno(aluno).then((alunoNew) => {
						SessionData.aluno.list[SessionData.aluno.selecionado] = alunoNew;

						verificarAvaliacaoInstitucional(aluno);
					});
				} else {
					verificarAvaliacaoInstitucional(aluno);
				}
			});
		}
		
		function selecionarAlunoAsync(aluno) {
			var alunoResp = []; 
			const matriculaAluno = SessionData.aluno.list[0].matricula;

			let promiseContratoLogin = MatriculaService.verificaExibeContratoLogin(aluno.matricula).then(function success(result) {
				Storage.set("exibirContrato", result.valor);

				if (!result.valor)
					$rootScope.isContractNotRequired = true;

				var a = { 'list': alunoResp, 'selecionado': 0 };
			
				// Altera o aluno na sessão.
				a.list.push(aluno);
				SessionData.aluno = a;

				if(matriculaAluno != aluno.matricula) {
					// Atualiza o conteúdo do SubHeader
					angular.element("#idSubHeader").html(a.list[0].user.name);

					setTimeout(function (){
						loading(true);
						angular.element('#globalNavbar').removeClass('hide');
						endsLoading();
					}, 1000);
			
					function endsLoading(){
						setTimeout(()=>{
						loading(false);
						}, 500);
					}

					$rootScope.alunoHasChanged = true; // Utilizado inicialmente no HomeController para indicar que o aluno já foi selecionado.
					// deferred.resolve(true);
					// return deferred.promise;
					
				}
			},  function error(result) {
				let errorMsg;
				if (result && result.mensagemDetalhada) {
					errorMsg = result.mensagemDetalhada;
				} else {
					errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
				}
				Notification.error(errorMsg);
			});

			let paramsLgpd = {
				codPessoa: aluno.usuarioLogado.id,
				codAluno: aluno.codAluno,
				codResp: "null", //TODO Mudar para o id do responsável quando o mesmo estiver retornando da API
				sistema: "AOnlineResp"
			}

			let promiseContratoLGPD = MatriculaService.verificaExibeContratoLGPD(paramsLgpd).then(function success(result) {
				Storage.set("_contratoLgpd", JSON.stringify(result));
				
				if ((!result) || (result.necessita_aceite_lgpd && result.necessita_aceite_lgpd.toUpperCase() == "N"))
					$rootScope.isLgpdNotRequired = true; 	
				
			}, function error(result) {
				let errorMsg;
				if (result && result.mensagemDetalhada) 
					errorMsg = result.mensagemDetalhada;
				else 
					errorMsg = $translate.instant('Aluno.Aceite.Contrato.Login.Servidor.Indisponivel');
				
				Notification.error(errorMsg);
			});
			
			return Promise.all([promiseContratoLogin, promiseContratoLGPD]);

		}

		function verificarAvaliacaoInstitucional(aluno) {
			loading(true);
			if ($rootScope.isContractNotRequired && $rootScope.isLgpdNotRequired) {
				Aluno_AvaliacaoInstitucional.query({ codAluno: encodeURIComponent(Array.isArray(aluno) ? aluno[0].codAluno : aluno.codAluno) }, function sucess(result) {
					if (result.length > 0 && SessionData.aluno.list[0].transacoes.indexOf('avaliacaoinstitucional') > -1) {
						if (result.find(function (item) { return item.OBRIGATORIA === 'S'; })) {
							Storage.set('questionariosObrigatorios', true);
							redirecionaParaTelaDoAlunoSelecionado(aluno);
							return;
						}

						$ionicModal.fromTemplateUrl('./views/logged/_avaliacao.institucional.view.html', {
							scope: $rootScope,
							animation: 'slide-in-up',
							hardwareBackButtonClose: false,
							backdropClickToClose: false
						}).then(function (modal) {
							$rootScope.modalAvaliacao = modal
							$timeout(function () {
								modal.show();
							}, 500)
						});
						loading(false);
						redirecionaParaTelaDoAlunoSelecionado(aluno);
					} else {
						Storage.set('questionariosObrigatorios', false);
						redirecionaParaTelaDoAlunoSelecionado(aluno);
						return;
					}
				},
					function error(result) {
						var keys = Object.keys(reason);
						var str = '';

						for (var k in keys) {
							str += reason[k] + '\n';
						}
						loading(false);
						redirecionaParaTelaDoAlunoSelecionado(aluno);
						Notification.error('Problemas ao verificar a avaliação institucional. Erro: ' + str);

					});
			} else {
				redirecionaParaTelaDoAlunoSelecionado(aluno);
			}
		}

		function redirecionaParaTelaDoAlunoSelecionado(aluno) {
			loading(true);
			var temPermissao = SessionData.aluno.list[SessionData.aluno.selecionado].transacoes.find(element => element == $state.$current.self.pageId);
			var landingState = $state.$current;
			if (temPermissao) {
				if (!Storage.get('questionariosObrigatorios') && $state.$current.self.name == 'home.avaliacaoinstitucional-questionario-full') {
					$state.go('home.avisos', {}, { reload: true, notify: true });
				} else {
					loading(false);
					$state.go(landingState, {}, { reload: true, notify: true });
				}
			} else {
				UserService.getPaginaInicial(Array.isArray(aluno.list) ? aluno.list[0] : aluno).then(function (result) {
					if (result != undefined && result.paginaRedirecionamento !== null && result.paginaRedirecionamento !== '') {
						landingState = result.paginaRedirecionamento;

						if (landingState) {

							/*
							* Converte url relativa (e.g. '/home/avisos') para respectivo
							* state (e.g. 'home.avisos') quando necessário.
							*/

							landingState = landingState.replace(new RegExp('/', 'g'), '.');

							if (landingState.indexOf('.') === 0) {
								landingState = landingState.substring(1);
							}

							$log.debug('Página inicial alterada:', landingState);
						}
					}
				})
					.catch(function (err) {
						loading(false);
						$log.error('Erro no serviço "Página Inicial":', err);
					})
					.then(function () {
						loading(false);
						$state.go(landingState, {}, { reload: true, notify: true });
					})
			}
		};		

	this.redirectUrlRetorno = function(codPessoa, codAluno){
  	  let defer = $q.defer();
  	  var _objAluno = {
	          codAluno: codAluno,
	          codPessoa: codPessoa
	      };
  	  $resource(Configs.getApiServer() + '/pessoas/cod_pessoa/:codPessoa/cod_aluno/:codAluno/obter_url_retorno', {}, {
            cache: false,
            query: {
                method: 'GET'
            }
        })
        .query(_objAluno, function(result) {
            defer.resolve(result);
        }, function(err) {
            defer.reject(err);
        });

  	  return defer.promise;
    }

	this.geraLogConexao = function(params){
		let defer = $q.defer();
		
		$resource(Configs.getApiServer() + "/pessoas/grava-log-conexoes", {}, {
				query: {
						method: 'POST',
						cache: false
					}
			})
			.query(params, function(result) {
				defer.resolve(result);
			}, function(err) {
				defer.reject(err);
			});

		return defer.promise;
	}

	/*
	 * Carrega a foto do Aluno
	 */
	this.loadFotoAluno = function(aluno){

		if(aluno === undefined || aluno === null)
			return "img/nophoto.png";


		// Chama o serviço REST
		Aluno_Foto.query({codPessoa: aluno.id}, handleSuccess, handleFailure);

		// Tratamento de Sucesso da chamada ao serviço REST
	    function handleSuccess(result){
	    	if(result && result.foto !== null){
	    		aluno.foto = result.foto;
	    		aluno.foto = getAlunoImageURL(aluno);
	    	}
	    }

	    // Tratamento de Erro da Chamada ao serviço REST
	    function handleFailure(reason){

	        var keys = Object.keys(reason);
	        var str = '';
	        for (var k in keys) {
	          str += reason[k] + '\n';
	        }

	        Notification.error('[serviceAlunos.Aluno_Foto.handleFailure]\treason=' + str);
	    }

      // Gera o blob com a foto do aluno
      function getAlunoImageURL(aluno){
        var imgURL = "";

        if(aluno && (aluno.foto !== undefined) && (aluno.foto !== null) && (aluno.foto !== "null") && (aluno.foto.length > 0)){
          imgURL = URL.createObjectURL(toPngBlob(aluno.foto));
        } else {

          // Se não tiver foto, usa avatar padrão, de acordo com o sexo
          switch(aluno.sexo){
            case 'M':
              imgURL = "img/avatar_M.png";
              break;

            case 'F':
              imgURL = "img/avatar_F.png"
              break;

            default:
              imgURL = "img/nophoto.png";
          }
        }
        // Atualiza img do header com a foto do aluno selecionado
        angular.element("#sexoHeader").attr('src', imgURL);
        return imgURL;
      }

		// Converte para Blob do tipo imagem PNG
		function toPngBlob(strHexadecimal){
			var hexString = atob(strHexadecimal);
		    var aBuffer = new ArrayBuffer(hexString.length/2);
		    var byteBuffer = new Uint8Array(aBuffer);

		    for (let i=0; i<hexString.length; i+=2) {
		    	byteBuffer[i/2] = parseInt(hexString.slice(i,i+2),16);
		    }

		    var blob = new Blob([byteBuffer], {type: "image/png"});
		    return blob;
		}
	}

	this.listarResponsaveisAluno = function(codAluno) {
		let deferr = $q.defer();
		if (codAluno) {
		   $resource(Configs.getApiServer() + '/api/rest/alunos/responsaveis-aluno?codAluno=:codAluno',
			   {}, { 'query': { method: 'GET', isArray: true } }).query({
				 codAluno
			   }, function (response) {
				 deferr.resolve(response);
	
			   }, function(){
				   deferr.reject();
			   });
	   } else {
		   deferr.resolve(false);
	   }
	   return deferr.promise;
	}

	this.incluirFotoPerfilAluno = function(fotoPerfilDTO) {
		let deferr = $q.defer();
		if (fotoPerfilDTO) {
		  let defer = $q.defer();
		  $resource(Configs.getApiServer() + "/api/rest/alunos/fotoPerfil", {}, {
				  query: {
						  method: 'POST',
						  cache: false
					  }
			  })
			  .query(fotoPerfilDTO, function(result) {
				  defer.resolve(result);
			  }, function(err) {
				  defer.reject(err);
			  });
	
		  return defer.promise;
	   } else {
		   deferr.resolve(false);
	   }
	   return deferr.promise;
	  }

}])

.factory('appModalService',	['$ionicModal', '$rootScope', '$q', '$injector', '$controller', function($ionicModal, $rootScope, $q, $injector, $controller) {

	return {
		show: show
	}

	function show(templeteUrl, controller, parameters, options) {
		// Grab the injector and create a new scope
		var deferred = 	$q.defer(),
        			ctrlInstance,
        			modalScope = $rootScope.$new(),
        			thisScopeId = modalScope.$id,
        			defaultOptions = {
  							animation: 'animated flipInX',
  							hideDelay: 1020,
  							focusFirstInput: false,
  							backdropClickToClose: true,
  							hardwareBackButtonClose: true,
  							modalCallback: null
        			};

		options = angular.extend({}, defaultOptions, options);

		$ionicModal.fromTemplateUrl(templeteUrl, {
			scope: modalScope,
			animation: options.animation,
			focusFirstInput: options.focusFirstInput,
			backdropClickToClose: options.backdropClickToClose,
			hardwareBackButtonClose: options.hardwareBackButtonClose
		})
		.then(function (modal) {
			modalScope.modal = modal;

			modalScope.openModal = function () {
				modalScope.modal.show();
			};

			modalScope.closeModal = function (result) {
				deferred.resolve(result);
				modalScope.modal.hide();
			};

			modalScope.$on('modal.hidden', function (thisModal) {
				if (thisModal.currentScope) {
					var modalScopeId = thisModal.currentScope.$id;
					if (thisScopeId === modalScopeId) {
						deferred.resolve(null);
						_cleanup(thisModal.currentScope);
					}
				}
			});

			// Invoke the controller
			var locals = { '$scope': modalScope, 'parameters': parameters };
			var ctrlEval = _evalController(controller);
			ctrlInstance = $controller(controller, locals);
			if (ctrlEval.isControllerAs) {
				ctrlInstance.openModal = modalScope.openModal;
				ctrlInstance.closeModal = modalScope.closeModal;
			}

			modalScope.modal.show().then(function () {
				modalScope.$broadcast('modal.afterShow', modalScope.modal);
			});

			if (angular.isFunction(options.modalCallback)) {
				options.modalCallback(modal);
			}

		}, 
		function (err) {
			deferred.reject(err);
		});

		return deferred.promise;
	}

	function _cleanup(scope) {
		scope.$destroy();
		if (scope.modal) {
			scope.modal.remove();
		}
	}

	function _evalController(ctrlName) {
		var result = {
				isControllerAs: false,
				controllerName: '',
				propName: ''
		};

		var fragments = (ctrlName || '').trim().split(/\s+/);

		result.isControllerAs = fragments.length === 3 && (fragments[1] || '').toLowerCase() === 'as';

		if (result.isControllerAs) {
			result.controllerName = fragments[0];
			result.propName = fragments[2];
		} else {
			result.controllerName = ctrlName;
		}

		return result;
	}
}]);