angular
    .module('MyApp')
    .service('DisciplinasMatriculadasService', ['$resource', 'DownloadArquivo', '$ionicLoading', '$q', 'SessionData', 'Configs', 
    	function($resource, DownloadArquivo, $ionicLoading, $q, SessionData, Configs) {
      var _aluno = SessionData.aluno;
      var _msgErro = { msgErro: 'Error student undefined' };
           
        this.obterDisciplinasMatriculadas = function() {
            let defer = $q.defer();
            
            var _objAluno = {
                    codAluno: SessionData.aluno.list[_aluno.selecionado].codAluno,
                    codPessoa: SessionData.aluno.list[_aluno.selecionado].id,
                    periodo: null,
                    ano: null
            };
            
            if (_objAluno) {
                $resource(Configs.getApiServer() + '/matricula/obterDisciplinasMatriculadasPreMatriculadas', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                    
                    })
                    .query(_objAluno, function(result) {
                        defer.resolve(result);
                    }, function(err) {
                    defer.reject(err);
                });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;          
        }

        this.obterPlanosDidaticos = function(disciplina) {
            let defer = $q.defer();
        
            var _objRequest = {
                codDisciplina: disciplina.codDisciplina,
                codTurma: disciplina.turma,
                periodo: SessionData.aluno.list[_aluno.selecionado].semestre,
                ano: disciplina.ano
            };
        
            if (_objRequest) {
                let url = Configs.getApiServer() + '/matricula/listaPlanosDidaticosPedagogicos';
        
                $resource(url, {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true,
                        params: _objRequest
                    }
                })
                .query(_objRequest, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    console.log('Error:', err);
                    defer.reject(err);
                });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;
        }
        
        this.obterDetalhePlanosDidaticos = function(disciplina, codDisc) {
            let defer = $q.defer();
        
            var _objRequest = {
                codDisciplina: codDisc,
                codTurma: disciplina.turma,
                periodo: SessionData.aluno.list[_aluno.selecionado].semestre,
                ano: disciplina.ano
            };
        
            if (_objRequest) {
                let url = Configs.getApiServer() + '/matricula/listaItensPlanosDidaticosPedagogicos';

                $resource(url, {}, {
                    cache: false,
                    query: {
                        method: 'GET',
                        isArray: true,
                        params: _objRequest
                    }
                })
                .query(_objRequest, function(result) {
                    defer.resolve(result);
                }, function(err) {
                    console.log('Error:', err);
                    defer.reject(err);
                });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;
        }

        this.obterCriterioAvaliacao = function obterCriterioAvaliacao(params) {
            let defer = $q.defer();
            
            var _objRequest = {
                codDisciplina: params.codDisciplina,
                codTurma: params.turma,
                ano: params.ano,
                semestre: params.semestre
            };
            
            if (_objRequest) {
                $resource(Configs.getApiServer() + '/matricula/obter-criterio-avaliacao', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }                    
                    })
                    .query(_objRequest, function(result) {
                        defer.resolve(result);
                    }, 
                    function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;          
        }

        this.listarAvaliacoesDocente = function listarAvaliacoesDocente(params) {
            let defer = $q.defer();
            
            var _objRequest = {
                codDocente: params.codDocente,
                codDisciplina: params.codDisciplina,
                codTurma: params.turma,
                ano: params.ano,
                semestre: params.semestre
            };
            
            if (_objRequest) {
                $resource(Configs.getApiServer() + '/matricula/listar-avaliacoes-docente', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }                    
                    })
                    .query(_objRequest, function(result) {
                        defer.resolve(result);
                    }, 
                    function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;          
        }

        this.listarAgendaDiario = function listarAgendaDiario(params) {
            let defer = $q.defer();
            
            var _objRequest = {
                codDisciplina: encodeURI(params.codDisciplina),
                codTurma: encodeURI(params.turma),
                ano: params.ano,
                semestre: params.semestre
            };
            
            if (_objRequest) {
                $resource(Configs.getApiServer() + '/matricula/listar-agenda-diario', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }                    
                    })
                    .query(_objRequest, function(result) {
                        defer.resolve(result);
                    }, 
                    function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;          
        }

        this.obterDetalhesAgenda = function obterDetalhesAgenda(params) {
            let defer = $q.defer();
            
            var _objRequest = params;
            
            if (_objRequest) {
                $resource(Configs.getApiServer() + '/matricula/listar-detalhes-agenda', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }                    
                    })
                    .query(_objRequest, function(result) {
                        defer.resolve(result);
                    }, 
                    function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;          
        }
		
		this.listarNotasHistoricoMatriculaAlunoDisciplina = function listarNotasHistoricoMatriculaAlunoDisciplina(params) {
            let defer = $q.defer();
            
            var _objRequest = {
				aluno: encodeURI(params.aluno),
				ordem: '',
				ano: params.ano,
                semestre: params.semestre,
                disciplina: encodeURI(params.disciplina)
            };
            
            if (_objRequest) {
                $resource(Configs.getApiServer() + '/matricula/listar-notas-historico-matricula-aluno-disciplina', {}, {
                    cache: false,                  
                    query: {
                        method: 'GET',
                        isArray: true
                    }                    
                    })
                    .query(_objRequest, function(result) {
                        defer.resolve(result);
                    }, 
                    function(err) {
                        defer.reject(err);
                    });
            } else {
                defer.reject({ _msgErro });
            }
            return defer.promise;          
        }
      
    }]);