angular
    .module('historicoCabecalhoModule')
    .controller('HistoricoCabecalhoController', [
        '$scope',
        '$state',
        '$log',
        'SessionData',
        'DownloadArquivo',
        'Configs',
        '$ionicLoading',
        function($scope, $state, $log, SessionData, DownloadArquivo, Configs, $ionicLoading) {
            var historicoCtrl = this;
            historicoCtrl.id = 'HistoricoCabecalhoController';

            historicoCtrl.getWindowWidth = function() {
                return window.innerWidth;
            }
        }]);
