angular.module('MyApp')
	.service('FrequenciaService', ['$resource','$q','SessionData','Configs', 
		function($resource, $q, SessionData, Configs) {
			var _msgErro = {
			msgErro : 'Error frequência não encontrada'
			};

        this.listarDetalhesFrequencia = function(codAluno, ano, codDisciplina, codTurma, semestre) {
            let defer = $q.defer();
            var detalhesFaltasParamsDTO = {
                'cod_aluno': codAluno,
                'ano': ano,
                'cod_disciplina': codDisciplina,
                'cod_turma': codTurma,
                'semestre': semestre
            }

            $resource(
                Configs.getApiServer() + '/v2/alunos/:cod_aluno/detalhes-faltas?ano=:ano&codDisciplina=:cod_disciplina&codTurma=:cod_turma&semestre=:semestre', {}, {
                    cache : false,
                    query : {
                        method : 'GET'
                    }
            }).query(detalhesFaltasParamsDTO, function(result) {
                defer.resolve(result);
            }, function(err) {
                defer.reject(err);
            });

            return defer.promise;
        }
 }]);