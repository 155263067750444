/**
 * Controller do Component Boleto Detail (boletoDetail)
 */
(function(){
	angular.module('boletoDeckModule').controller('BoletoDetailComponentController', ['$scope', '$rootScope', 'Configs', '$log', 'Notification', '$ionicModal', 'Financeiro.Boletos.Download', '$http', 'SessionData', '$ionicLoading', '$window', '$timeout', 'DownloadArquivo', 'CobrancaService', '$state', BoletoDetailComponentController]);
	
	function BoletoDetailComponentController($scope, $rootScope, Configs, $log, Notification, $ionicModal, Financeiro_Boletos_Download, $http, SessionData, $ionicLoading, $window, $timeout, DownloadArquivo, CobrancaService, $state){
		var ctrl = this;
		//ctrl.boleto = [];
		$log.debug('[BoletoDetailComponentController]:ctrl=', ctrl);
		
		ctrl.openBoletoDetail = openBoletoDetail;
		ctrl.closeBoletoDetail = closeBoletoDetail;
		ctrl.copiarNumeroBoleto = copiarNumeroBoleto;
		ctrl.downloadBoleto = downloadBoleto;
		ctrl.downloadBoletoExt = downloadBoletoExt;
		
		ctrl.prorrogarVencimentoBoleto = function(isDownload){
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});
			
			CobrancaService.prorrogarVencimentoBoleto(ctrl.boleto.codigoBoleto).then(function(result){
				$ionicLoading.hide();

				if (isDownload){
					if (result.valor){
						CobrancaService.downloadBoletoCobranca(result.valor);
						$rootScope.$broadcast('emitAtualizaBoletos');
					} else {
						CobrancaService.downloadBoletoCobranca(ctrl.boleto.codigoBoleto);
					}
				} else {
					if (result.valor){
						ctrl.continuarAberturaCobranca(result.valor, isNew = true);
					} else {
						ctrl.continuarAberturaCobranca(ctrl.boleto.codigoBoleto, isNew = false);
					}
				}

			}).catch(function(err) {
				$ionicLoading.hide();
			});

		}
		
		// Abre os detalhes do boleto
		function openBoletoDetail(){
			$log.debug('[BoletoDetailComponentController.openBoletoDetail()]:boleto=', ctrl.boleto);			
			CobrancaService.getExibeProrrogaBoleto(ctrl.boleto.codigoBoleto)
				.then(function(result) {
					if (result.valor){
						ctrl.prorrogarVencimentoBoleto(false);
					} else {
						ctrl.continuarAberturaCobranca(ctrl.boleto.codigoBoleto, isNew = false);
					}
				})
				.catch(function(err) {
					Notification.error(err);
				});
		}

		ctrl.continuarAberturaCobranca = function(codigoBoleto, isNew){
			//Carregando valores do popup
			let promise1 = CobrancaService.listarItensDetalheBoleto(codigoBoleto)
             .then(function(result) {
				 ctrl.boleto.itensBoleto = result;
				 ctrl.boleto.itensBoleto.codigoBoleto = codigoBoleto;				 
             })
             .catch(function(err) {
                 Notification.error(err);
             });
			 
			let promise2 = CobrancaService.getDadosImpressaoBoleto(codigoBoleto)
             .then(function(result) {
				if(result.ipte.includes('O boleto não pode ser impresso')){
					ctrl.boleto.precisaAceite = true;
				}
				if(result.ipte.length > 0 && isNaN(result.ipte.substring(0,3))){
					ctrl.boleto.ComErro = true;
				}
            	 ctrl.boleto.codigoBarras = result.ipte;
             })
             .catch(function(err) {
                 Notification.error(err);
			 });
			 
			 Promise.all([promise1, promise2]).then(function(){
				if (isNew) $rootScope.$broadcast('emitAtualizaBoletos');
			 }).catch(erro => { 
				$log.debug("erro.message")
				if (isNew) $rootScope.$broadcast('emitAtualizaBoletos');
			  });
								
			ctrl.modalBoleto.show();
		}
		
		
		// Fecha os detalhes do boleto
		function closeBoletoDetail(){
			$log.debug('[BoletoDetailComponentController.closeBoletoDetail()]:boleto=', ctrl.boleto);
			ctrl.modalBoleto.hide();			
		}
		
		
		// Copia o número do boleto para a área de transferência
		function copiarNumeroBoleto(){
			if(!Clipboard.isSupported()){
				Notification.warning('Função não suportada no navegador em uso.');
				return;
			}
			if(ctrl.boleto.ComErro){
				Notification.warning(ctrl.boleto.codigoBarras);
				return;
			}
			
			ctrl.clipboard = new Clipboard('#copy-button');
			Notification.success("Número do Boleto foi copiado!");
			
		}
		
		// Download de arquivo pdf do Boleto
		function downloadBoletoExt(boleto){
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});

			CobrancaService.getExibeProrrogaBoleto(boleto)
				.then(function(result) {					
					if (result.valor){
						ctrl.prorrogarVencimentoBoleto(true);
					} else {
						$ionicLoading.show({
							content : 'Loading',
							animation : 'fade-in',
							showBackdrop : true,
							maxWidth : 200,
							showDelay : 1
						});
						
						CobrancaService.downloadBoletoCobranca(boleto);
					}
				})
				.catch(function(err) {
					Notification.error(err);
				});			
		}

		function downloadBoleto(){
			$ionicLoading.show({
				content : 'Loading',
				animation : 'fade-in',
				showBackdrop : true,
				maxWidth : 200,
				showDelay : 1
			});

			CobrancaService.downloadBoletoCobranca(ctrl.boleto.itensBoleto.codigoBoleto);
		}
		
		$ionicModal.fromTemplateUrl('js/custom-components/boleto-detail/boleto-itens-modal.html', {
			scope: $scope,
			animation: 'slide-in-up',
		}).then(function(modalBoleto) {
			ctrl.modalBoleto = modalBoleto;
		});
	}
})();
