(function () {
    angular.module('card')
        .controller('CardComponentController', ['$log', '$scope', '$ionicScrollDelegate', '$state', 'TypeEnum', '$timeout', '$rootScope', 'RelatorioService', 'SessionData', 'Configs', '$ionicLoading', 'DownloadArquivo', '$ionicModal', 'AonlineUtils', 'DisciplinasMatriculadasService',
            function ($log, $scope, $ionicScrollDelegate, $state, TypeEnum, $timeout, $rootScope, RelatorioService, SessionData, Configs, $ionicLoading, DownloadArquivo, $ionicModal, AonlineUtils, DisciplinasMatriculadasService) {
                
                let vm = this;
                
                vm.arrayFiltroSituacao = [];     
                vm.arraySituacoes = [];         

                vm.stateIs = function(state){
                	return $state.is(state);
                }
                
                vm.checkedItems = function(){  
                	var selectedItems = [];
	                angular.forEach(vm.itens, function(value, index){
	        			if(value.checked){
	        				selectedItems.push(value);
	        			}
	        		});
	                return selectedItems;
                }
                
                vm.checkItem = function (item) {
                	$rootScope.$broadcast('CardCreditSelectionChanged');
                }

                vm.downloadRelatorio = function(item){                    
                    let params = {
                        grupoRelatorio: 'FinanceiroNG',
                        relatorio: 'CartaChargeback',
                        sistema: 'Lyceum',
                        parameterMap: {
                            p_id: item.chargeBack
                        }
                    }
                    RelatorioService.downloadReportServiceEP(params);
                }

                vm.getWindowWidth = function () {
                    return window.innerWidth;
                }

                vm.itemStyle = function () {
                    if (vm.cardItemStyle)
                        return 'item ' + vm.cardItemStyle;
                    return 'item';
                }

                vm.getValueOfType = function (item, column) {
                    switch (column.type) {
                        case TypeEnum.DATE:
                            return item[column.id] != null && item[column.id] != '' ? moment(item[column.id]).format('DD/MM/YYYY') : '';
                        case TypeEnum.STRING:
                            return item[column.id];
                        case TypeEnum.CURRENCY:
                            return typeof item[column.id] == 'number' ? convertToCurrency(item[column.id]) : item[column.id];
                        case TypeEnum.CARD:
                            return item[column.id];
                        default:
                            return item[column.id];
                    }
                }

                function convertToCurrency(input = 0) {
                    return parseFloat(input).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                }

                $ionicModal.fromTemplateUrl('./views/logged/historico.notas-media-disciplina.modal.html', {
                    scope: $scope,
                    animation: 'slide-in-up'
                }).then(function(modal) {
                    $scope.modal = modal;
                });

                // Exibe dados do registro num modal
                $scope.exibeNotasMediaDisciplina = function(disciplinaHistorico){
                    AonlineUtils.showLoading(true);
                    DisciplinasMatriculadasService.listarNotasHistoricoMatriculaAlunoDisciplina(disciplinaHistorico).then((response) => {
                        AonlineUtils.showLoading(false);
                        $scope.notasMediaDisciplina = angular.copy(response);
                        $scope.nomeDisciplina = disciplinaHistorico.nomeDisciplina;
                    }, (err) => {
                        $scope.notasMediaDisciplina = [];
                        $scope.nomeDisciplina = disciplinaHistorico.nomeDisciplina;
                        AonlineUtils.showLoading(false);
                    })
                
                    $scope.modal.show();
                }
                
                vm.filtrosSituacao = function () {      
                    vm.arrayFiltroSituacao = [];              
                    angular.forEach(vm.situacoesSelecionadas, function(value, index){	                     	
	        			vm.arrayFiltroSituacao.push(value.nomeSituacao);	        			
	        		})
                };

                vm.obterHistoricoPdf = function() {
                    let _aluno = SessionData.aluno;
                    let codAluno = _aluno.list[_aluno.selecionado].codAluno;
                      let params = {};
                            params.url = Configs.getApiServer() + '/matricula/cod_aluno/' + codAluno + '/exibir_arquivo/false/obtem_historico_simplificado_pdf?situacoes_array='+vm.arrayFiltroSituacao.toString();
                            params.method = "GET";
                            $ionicLoading.show({
                                content : 'Loading',
                                animation : 'fade-in',
                                showBackdrop : true,
                                maxWidth : 200,
                                showDelay : 1
                            });

                            DownloadArquivo.downloadArquivo(params);
                };            

                vm.inicializarArraySituacoes = function() { 
                    if  ( vm.arraySituacoes.length == 0) {                                  
                        angular.forEach(vm.itens, function(value, index){
                            let jsonObj = {codSituacao: vm.arraySituacoes.length+1, nomeSituacao: value.situacao};
                           
                            let hasObj = vm.arraySituacoes.some(situ => situ['nomeSituacao'] === value.situacao );

                            if(!hasObj){                              
                                vm.arraySituacoes.push(jsonObj);
                            }
                        });
                    }
                };
                
                $scope.$watch('vm.itens', function(newItens){
                    if (newItens != null && newItens.length > 0) {
                        console.log('Carregando situações');
                        vm.inicializarArraySituacoes();
                    }                  
                });	
            }
        ]
        );
})();
