angular
    .module('MyApp')
    .service('CarteirinhaEstudantilService', ['$resource', '$q', '$http', 'SessionData', 'Configs', 'FileSaver', function($resource, $q, $http, SessionData, Configs, FileSaver) {
      var codAluno = SessionData.aluno.list[SessionData.aluno.selecionado].codAluno;
      var _msgErro = { msgErro: 'Error student undefined' };

        this.obterDataVencimentoPeriodo = function() {
          let defer = $q.defer();
            $resource(Configs.getApiServer() + '/v2/alunos/aluno/' + codAluno + '/vencimento-periodo', {}, {
                  cache: false,
                  query: {
                      method: 'GET',
                      isArray: true
                  }
                })
                .get(function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
            return defer.promise;
        }

        this.obterConfigAlunoResp = function() {
          let defer = $q.defer();
            $resource(Configs.getApiServer() + '/v2/alunos/aluno/' + codAluno + '/config-aluno-resp', {}, {
                  cache: false,
                  query: {
                      method: 'GET',
                      isArray: true
                  }
                })
                .get(function(result) {
                    defer.resolve(result);
                }, function(err) {
                defer.reject(err);
            });
            return defer.promise;
        }

        this.efetuarDownloadRelatorioAluno = function(dados) {
            $http.post(Configs.getApiServer() + '/v2/alunos/relatorio-dados-aluno', dados, { responseType: 'arraybuffer' }).then(
              response => {
                  saveFileAs(response);
              }
            );
            function saveFileAs(response) {
                var contentDisposition = response.headers("content-disposition");
                var fileName = contentDisposition.substr(contentDisposition.indexOf("filename=") + 9);
                fileName = fileName.replace(/\"/g, "");
                var contentType = response.headers("content-type");
                var blob = new Blob([response.data], { type: contentType });
                FileSaver.saveAs(blob, fileName);
            }
        }


        this.obtemImagemDoc = function (dados) {
          let defer = $q.defer();

          $resource(Configs.getApiServer() + '/v2/alunos/imagem-documento', {}, {
            cache: false,
            query: {
              method: 'GET',
              isArray: false
          }
          })
          .query({
            codAluno: encodeURIComponent(dados.codAluno),
            tipoDocumento: encodeURIComponent(dados.tipoDocumento)
            },
            function (response) {
              defer.resolve(response);
            },
            function (err) {
              defer.reject(err);
            }
          );

          return defer.promise;
        }

    }]);
