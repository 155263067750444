angular.module('MyApp')
  .service('UserService', ["Configs", "SessionData", "$resource", "$q", "$http", "Storage", function (Configs, SessionData, $resource, $q, $http, Storage) {
    let vm = this;
    var url;
    
    function buscarTransacoesAutorizadas(aluno) {
      let deferr = $q.defer();

      if (aluno) {
        $resource(Configs.getApiServer() + '/pessoas/codPessoa/:pessoaId/codAluno/:alunoId/buscarTransacoesAutorizadas',
          {}, { 'query': { method: 'GET' } }).query({
            pessoaId: aluno.usuarioLogado.id,
            alunoId: encodeURIComponent(aluno.codAluno)
          }, function (response) {
            if (response)
              deferr.resolve(response)
            else
              deferr.resolve(false)
          });
      } else {
        deferr.resolve(false);
      }

      return deferr.promise;
    }

    function buscarMenuAluno(aluno) {
      let deferr = $q.defer();

      if(aluno) {
        $http({
          url: Configs.getApiServer() + `/pessoas/menu-aluno-agrupado?aluno=${encodeURIComponent(aluno.codAluno)}&pessoa=${aluno.usuarioLogado.id}`
        })
        .success(function(retorno){
          deferr.resolve(retorno);
        })
        .error(function(erro){
          deferr.resolve(false);
        });
      }
      return deferr.promise;
    }

    function adicionaPaginasDoMenu(menu, aluno) {
      for(itens of menu) {
        for(item of itens.itens) {
          let strUrl = item.url;
          strUrl = (strUrl !== undefined && strUrl.startsWith('.')) ? strUrl.replace('.', '') : strUrl;
          
          if(aluno.permittedPages.indexOf(strUrl) === 1){
            aluno.permittedPages.push(strUrl)
          }
          
        }
      }
    }

    function buscarTransacoesAutorizadasAlunoFunc(alunoOld, deferred, alunoNew) {
      if (!alunoNew) alunoNew = alunoOld;
      if (alunoOld) {
        buscarTransacoesAutorizadas(alunoOld).then(function (transacoes) {
          alunoOld.transacoes = transacoes.transoesAutorizadas;
          alunoOld.paginaInicial = transacoes.paginaInicial;
          alunoOld.permittedPages = transacoes.transoesAutorizadas;

          alunoOld.urls = transacoes.urlsAutorizadas.map(str => 
            str!=null && str!== undefined && str.startsWith('.') ? str.substring(1):str
          );
          
          let transacoesPermitidas = alunoOld.urls.concat(alunoOld.transacoes);
          alunoOld.permittedPages = [...new Set(transacoesPermitidas)];

          if(Storage.get("autoLogin")) {
            buscarMenuAluno(alunoOld).then(function (menu) {
              adicionaPaginasDoMenu(menu, alunoNew); 
              return deferred.resolve(alunoNew);
            })            
          } else {
             return deferred.resolve(alunoNew);
          }          
        })

      } else {
        return deferred.resolve(alunoNew);
      }
    }

    this.buscarTransacoesAutorizadasAluno = function (aluno) {
      let deferr = $q.defer();
      buscarTransacoesAutorizadasAlunoFunc(aluno, deferr);
      return deferr.promise;
    }
    this.alterarTemaAplicacao = function () {
      let  deferr  =  $q.defer();
      let  usuario  = SessionData.aluno.list[SessionData.aluno.selecionado] ? SessionData.aluno.list[SessionData.aluno.selecionado] : SessionData.user;      

      $resource(Configs.getApiServer() +'/pessoas/codPessoa/:pessoaId/alterarTemaAplicacao?codAluno=' + encodeURIComponent(encodeURIComponent(usuario.codAluno)),
        {}, {  'query':  {  method:  'GET'  } }).query({
          pessoaId: usuario.id,
        }, function (result) {
          deferr.resolve(result);
        }, function (err) {
          deferr.reject(err);
        });
      return  deferr.promise;
    }
    
    this.gerarToken = function(login, path) {
    	let  defer  =  $q.defer();
    	var params = {login: login, path: path};

      $resource(Configs.getApiServer() + '/pessoas/reset_senha_aluno', {}, {
        query: {
          method: 'POST',
          isArray: false
        }
      })
      .query(params, function(result) {
        defer.resolve(result);
      }, function(err) {
        defer.reject(err);
      });

      return defer.promise;
    }

    this.resetarSenha = function(token, senha, senhaRepete) {
    	let  defer  =  $q.defer();
      var params = {
        "codigo": token,
        "senha": senha, 
        "repeticaoSenha": senhaRepete
      }

      $resource(Configs.getApiServer() + '/pessoas/cadastra_nova_senha_aluno', {}, {
        query: {
          method: 'POST',
          isArray: false
        }
      })
      .query(params, function(result) {
        defer.resolve(result);
      }, function(err) {
        defer.reject(err);
      });

      return defer.promise;
    }
    
    this.setTheme = function () {
      if (!SessionData.isEmpty('user')) {

        let deferr = $q.defer();

        this.alterarTemaAplicacao()
          .then(function (result) {
            if (result != null && result.tema != null){
              const folderRest = 'css/' + result.tema;
              const head = document.getElementsByTagName('head')[0];
              var link = document.createElement('link');
              link.rel = 'stylesheet';
              link.type = 'text/css';
              link.href = folderRest + '/custom.css';
              link.media = 'all';
              head.appendChild(link);

              deferr.resolve();
            }
          })
          .catch(function () {
            deferr.resolve();
          });
        return deferr.promise;
      }
    }

    // Retorna a página inicial definida para o aluno
    this.getPaginaInicial = function(aluno){
    	let deferr = $q.defer();

    	$resource(Configs.getApiServer() +'/pessoas/cod_pessoa/:cod_pessoa/cod_aluno/:cod_aluno/obter_pagina_inicial', 
    			{}, {  'query':  {  method:  'GET'  } }).query({
    				cod_pessoa: aluno.usuarioLogado.id,
    			  	cod_aluno:  encodeURIComponent(aluno.codAluno)
    			}, function (response) {
    	          deferr.resolve(response);
    	        }, function (err) {
    	          deferr.reject(err);
    	        });

    	return deferr.promise;
    }
    
    // Obtém a próxima página para redirecionamento com base na página de origem
    this.getProximaPagina = function(codPessoa, codAluno, paginaOrigem){
    	let deferr = $q.defer();

    	$resource(Configs.getApiServer() +'/pessoas/codPessoa/:cod_pessoa/codAluno/:cod_aluno/paginaOrigem/:pagina_origem/obtem_proxima_pagina', 
    			{}, {  'query':  {  method:  'GET'  } }).query({
    				cod_pessoa: codPessoa,
    			  cod_aluno:  encodeURIComponent(codAluno),
            pagina_origem:  encodeURIComponent(paginaOrigem)
    			}, function (response) {
    	          deferr.resolve(response);
    	        }, function (err) {
    	          deferr.reject(err);
    	        });

    	return deferr.promise;
    }

  }]);
